<template>
  <div>
    <div v-if="masterUser">
      <b-card :title="masterUser.name" header-tag="header" footer-tag="footer">
        <b-card-text>
          <b-form ref="form" @submit.prevent>
            <b-tabs>
              <b-tab active title="Umum">
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Nama" label-for="nama">
                      <b-form-input
                        id="nama"
                        v-model="masterUser.name"
                        placeholder="nama"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Rubah Password">
                <b-row>
                  <!-- old password -->
                  <b-col md="12">
                    <b-form-group
                      label="Old Password"
                      label-for="account-old-password"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="account-old-password"
                          v-model="passwordOld"
                          name="old-password"
                          :type="passwordFieldTypeOld"
                          placeholder="Old Password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIconOld"
                            class="cursor-pointer"
                            @click="togglePasswordOld"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <!--/ old password -->
                  <!-- new password -->
                  <b-col md="12">
                    <b-form-group
                      label-for="account-new-password"
                      label="New Password"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="account-new-password"
                          v-model="passwordNew"
                          :type="passwordFieldTypeNew"
                          name="new-password"
                          placeholder="New Password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIconNew"
                            class="cursor-pointer"
                            @click="togglePasswordNew"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <!--/ new password -->
                  <!-- retype password -->
                  <b-col md="12">
                    <b-form-group
                      label-for="account-retype-new-password"
                      label="Retype New Password"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="account-retype-new-password"
                          v-model="passwordRetype"
                          :type="passwordFieldTypeRetype"
                          name="retype-password"
                          placeholder="New Password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIconRetype"
                            class="cursor-pointer"
                            @click="togglePasswordRetype"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <!--/ retype password -->

                  <b-col cols="12">
                    <b-button
                      href="#"
                      variant="primary"
                      class="mt-2"
                      :disabled="disableBtnSimpanPassword"
                      @click="simpanPassword()"
                    >
                      <b-spinner v-if="disableBtnSimpanPassword" small />
                      Simpan
                    </b-button>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Role">
                Not Available
              </b-tab>
            </b-tabs>
          </b-form>
        </b-card-text>
      </b-card>
    </div>
    <div v-else>
      {{ loadingMessage }}
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BForm,
  BTabs,
  BTab,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { api } from '@/hap-sia/setup'

export default {
  components: {
    BCard,
    BCardText,
    BForm,
    BTabs,
    BTab,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loadingMessage: 'Loading...',
      masterUser: null,
      passwordOld: null,
      passwordNew: null,
      passwordRetype: null,
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      disableBtnSimpanPassword: false,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  async created() {
    let userId = this.$route.params.id
    // this.makeToast('warning', this.$route.params.id)
    if (!userId) {
      //  ambil dari storage
      const user = JSON.parse(localStorage.getItem('userData'))
      userId = user.id
    }
    try {
      const result = await api().get(`user/${userId}`)
      this.masterUser = result.data
      //   this.makeToast('success', 'mantap')
      //   console.log(result)
    } catch (error) {
      console.log(error.response)
      this.makeToast(
        'danger',
        error.response.data.message ? error.response.data.message : error,
      )
    }
  },
  methods: {
    makeToast(variant = null, content) {
      this.$bvToast.toast(content, {
        title: 'Notifikasi',
        variant,
        solid: true,
      })
    },
    async simpanPassword() {
      if (!this.passwordOld || !this.passwordNew || !this.passwordRetype) {
        this.makeToast('danger', 'Kolom password harus terisi semua')
        return
      }
      if (this.passwordNew !== this.passwordRetype) {
        this.makeToast('danger', 'Password baru tidak sesuai')
        return
      }
      this.disableBtnSimpanPassword = true
      try {
        const tmpData = {
          id: this.masterUser.id,
          passwordOld: this.passwordOld,
          passwordNew: this.passwordNew,
        }
        await api().patch('user/password', tmpData)
        this.makeToast('success', 'Password berhasil disimpan')
      } catch (error) {
        this.makeToast('danger', error.response.data.message)
      }
      this.disableBtnSimpanPassword = false
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
